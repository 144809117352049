import { Link } from '@remix-run/react';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.css';

type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'disabled';

interface CommonProps {
  variant?: ButtonVariant;
}
type ButtonProps = React.ComponentProps<'button'> & CommonProps;
type LinkProps = React.ComponentProps<typeof Link> & CommonProps;
type Props = ButtonProps | LinkProps;

const baseStyles = 'inline-flex rounded px-4 py-2 justify-center min-h-[2.5rem]';

const Button = React.forwardRef<HTMLElement, Props>(({
  children,
  variant = 'primary',
  className,
  ...props
}, ref) => {
  let Component: 'button' | typeof Link = 'button';
  if ('to' in props) {
    Component = Link;
  }

  const variantWithDisabledOverride = 'disabled' in props && props.disabled ? 'disabled' : variant;

  return (
    <Component
      {...(props as any)}
      ref={(ref as any)}
      className={clsx(
        baseStyles,
        styles.button,
        styles[variantWithDisabledOverride],
        className
      )}>

      {children}
    </Component>);

});

Button.displayName = 'Button';

export default Button;